.react-trello-board {
    overflow-x: auto !important;
    overflow-y: auto !important;
    height: 65vh !important;
    flex-direction: column !important;
    padding: 0 !important;
}

.react-trello-board > div {
    display: flex;
    flex: 1;
}

.react-trello-lane {
    height: 100% !important;
    max-height: 100% !important;
    padding: 0 !important;
    margin: 0 !important;
    border-radius: 0 !important;
}

.react-trello-lane > div {
    display: flex;
    min-height: 100%;
    overflow-y: hidden;
}

.react-trello-lane > header > span {
    font-size: 14px;
    color: #525f7f;
    margin-left: 5px;
    margin-top: 5px;
}

.smooth-dnd-container .vertical {
    flex: 1;
}

.react-trello-card {
    margin-left: 10px;
    margin-right: 10px;
}

.react-trello-card > header > span {
    font-size: 14px;
    color: #525f7f;
    margin-right: 5px;
}

.react-trello-card > div {
    font-size: 16px;
    color: #525f7f;
    max-height: 100%;
}

.nav-tabs {
    cursor: pointer;
}

.tabs form {
    margin-top: 1.5rem;
}

form .custom-checkbox {
    margin-left: 20px;
    margin-top: 43px;
    font-weight: bold;
}

.select-invalid {
    border-color: #fb6340;
}

.tray-date-range > .select2.select2-container {
    width: 150px !important;
    margin-right: 12px;
    margin-left: 4px
}

.tray-date-range .select2-container .select2-selection--single {
    height: 2.0rem;
    line-height: 0.7;
}

.select2-container--default .select2-results > .select2-results__options {
    max-height: 260px !important;
}

    .react-trello-lane > div {
        min-height: auto;
        max-height: none;
    } 

    .react-trello-lane .smooth-dnd-container {
        overflow-y: hidden;
    }

    .react-trello-card {
        min-width: unset !important;
    }

@media (max-width: 1024px) {
    .react-trello-lane > div {
        min-width: 182px;
    } 

    .react-trello-card {
        width: 167px !important;
    }

    .react-trello-board {
        height: unset !important;
    }

    .react-trello-lane {
        max-height: unset !important;
    }
}

.tray-details-table, .tray-details-table > .table-responsive {
    padding-top: 0 !important;
}