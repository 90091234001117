.patient-order-row-new {
    background-color: #607d8b;
    color: white;
}

.patient-order-row-pending {
    background-color: #FFC107;
    color: white;
}

.patient-order-row-ready {
    background-color: #03a9f4;
    color: white;
}

.patient-order-row-complete {
    background-color: #4caf50;
    color: white;
}

.patient-order-modal-search-date {
    height: 2rem !important;
    margin-bottom: 10px;
}

.supplier-order-modal-search-date {
    height: 2rem !important;
    margin-bottom: 10px;
    display: inline-block;
}

.data-export .py-4.table-responsive {
    min-height: 500px;
}

#datatable-basic_filter, #datatable-basic_filter > label{
    width: 100%;
}

.process-btn {
    float: right;
}

.row {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.table-hover tr {
    cursor: pointer;
}