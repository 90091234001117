@font-face {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url('../googlefonts/quicksand/Quicksand-Light.ttf') format('ttf');
}

@font-face {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('../googlefonts/quicksand/Quicksand-Regular.ttf') format('ttf');
}

@font-face {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url('../googlefonts/quicksand/Quicksand-Medium.ttf') format('ttf');
}

@font-face {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url('../googlefonts/quicksand/Quicksand-SemiBold.ttf') format('ttf');
}

@font-face {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('../googlefonts/quicksand/Quicksand-Bold.ttf') format('ttf');
}

@font-face {
    font-family: 'Sunflower';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url('../googlefonts/sunflower/Sunflower-Light.ttf') format('ttf');
}

@font-face {
    font-family: 'Sunflower';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url('../googlefonts/sunflower/Sunflower-Medium.ttf') format('ttf');
}

@font-face {
    font-family: 'Sunflower';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('../googlefonts/sunflower/Sunflower-Bold.ttf') format('ttf');
}